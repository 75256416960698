import cx from 'classnames';
import { ReactSVG } from 'react-svg';

import {
  AnalyticsCard,
  AnalyticsContent,
  AnalyticsModule,
  Event,
  trackEvent,
} from '~/ui/components/analytics';
import { Breakpoint } from '~/ui/styles/grid';
import { Button } from '~/v1/components/button/button';
import { ButtonSize, ButtonType } from '~/v1/components/button/button.interface';
import { FullBleedCTA, FullBleedCTATrigger } from '~/v1/components/fullBleedCta/fullBleedCta';
import { Image } from '~/v1/components/image/image';
import { Link } from '~/v1/components/link/link';
import { Route } from '~/v1/constants/route';

import { type EventCardProps } from './event.interface';
import styles from './event.module.scss';
import { EventTypeLabel } from './event.utils';

export const EventCard: React.FC<EventCardProps> = ({
  href,
  title,
  date,
  time,
  className,
  ctaText,
  isEventsPage,
  isPastEvent,
  coverImage,
  image,
  type,
}) => {
  const eyebrow = EventTypeLabel[type];

  return (
    <FullBleedCTA label={title} className={cx(styles.card, className)}>
      {image && !coverImage ? (
        image.src.endsWith('.svg') ? (
          <ReactSVG
            src={image.src}
            className={cx(styles.smallImage, styles.svgLogo)}
            title={image.title}
          />
        ) : (
          <Image
            className={styles.smallImage}
            colSpan={{ [Breakpoint.SM]: 1 }}
            src={image.src}
            alt={image.title}
            aspectRatio="1/1"
          />
        )
      ) : null}

      {coverImage && (
        <Image
          aspectRatio="3/2"
          colSpan={{ [Breakpoint.SM]: 4, [Breakpoint.MD]: 3 }}
          className={styles.coverImage}
          src={coverImage.src}
          alt={coverImage.title}
        />
      )}

      <div className={styles.content}>
        <div className={cx('bodySmallTypography', styles.eyebrow)}>{eyebrow}</div>
        <div className="modulesTitleTypography truncate-4">{title}</div>

        {!isPastEvent && (
          <div className={styles.dateWrapper}>
            {date && <div className="bodySmallTypography">{date}</div>}
            {time && <div className="bodySmallTypography">{time}</div>}
          </div>
        )}

        <div className={cx(styles.ctaWrapper, 'expand')}>
          <FullBleedCTATrigger>
            <Link
              href={href}
              className={styles.detailsLink}
              onClick={() =>
                trackEvent(Event.CARD_CLICK, {
                  cardModule: isEventsPage
                    ? AnalyticsModule.UPCOMING_EVENTS
                    : AnalyticsModule.RELATED,
                  cardType: AnalyticsCard.EVENT,
                  cardContentType: isPastEvent
                    ? AnalyticsContent.PAST_EVENT
                    : AnalyticsContent.FUTURE_EVENT,
                  cardCTA: ctaText,
                  cardTitle: title,
                })
              }
              withIcon
            >
              {ctaText}
            </Link>
          </FullBleedCTATrigger>

          {!isEventsPage && (
            <Button
              href={Route.EVENTS}
              className={styles.eventPageLink}
              type={ButtonType.Secondary}
              size={ButtonSize.Tiny}
              isTransparent
            >
              See all events
            </Button>
          )}
        </div>
      </div>
    </FullBleedCTA>
  );
};
