import cx from 'classnames';

import { AnalyticsCard, AnalyticsModule, Event, trackEvent } from '~/ui/components/analytics';
import { Breakpoint } from '~/ui/styles/grid';
import { FullBleedCTA, FullBleedCTATrigger } from '~/v1/components/fullBleedCta/fullBleedCta';
import { Image } from '~/v1/components/image/image';
import { type IImage } from '~/v1/components/image/image.interface';
import { Link } from '~/v1/components/link/link';
import { TEST_ID } from '~/v1/constants/testId';

import styles from './idea.module.scss';

export interface IdeaCardProps {
  image: IImage;
  href: string;
  idea: string;
  question: string;
  className?: string;
}

export const IdeaCard: React.FC<IdeaCardProps> = ({ href, idea, question, className, image }) => {
  return (
    <FullBleedCTA
      label={idea}
      data-test-id={TEST_ID.CARD.IDEA}
      className={cx(styles.card, className)}
    >
      <div className={styles.content}>
        <Image
          className={styles.image}
          src={image.src}
          alt={image.alt}
          aspectRatio="4/5"
          colSpan={{ [Breakpoint.SM]: 4, [Breakpoint.MD]: 3 }}
        />
        <div className={cx('blockquoteTypography', styles.question)}>{question}</div>
        <div className="bodySmallTypography">{idea}</div>
      </div>

      <FullBleedCTATrigger>
        <Link
          href={href}
          className={styles.link}
          onClick={() =>
            trackEvent(Event.CARD_CLICK, {
              cardModule: AnalyticsModule.RELATED,
              cardType: AnalyticsCard.IDEA,
              cardCTA: 'Explore the Idea',
              cardTitle: question,
            })
          }
          withIcon
        >
          Explore the Idea
        </Link>
      </FullBleedCTATrigger>
    </FullBleedCTA>
  );
};
