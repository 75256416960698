import { AnalyticsContent } from '~/ui/components/analytics';
import { type ArticleCard } from '~/v1/_types/ArticleCard';
import { type NestedArticleCard } from '~/v1/_types/NestedArticleCard';
import { Route } from '~/v1/constants/route';

import { type OpinionCardType } from './opinion.interface';

export const mapOpinionCard = (card: ArticleCard | NestedArticleCard): OpinionCardType => {
  const authorPerson = card.articlePerson
    ? {
        image: card.articlePerson.image || undefined,
        name: card.articlePerson.name,
        contributorTitle: card.articlePerson.jobTitle,
        organization: card.articlePerson.areaOfWork.name,
      }
    : undefined;

  const authorExternalPerson = card.externalPerson
    ? {
        image: card.externalPerson.image || undefined,
        name: card.externalPerson.name,
        contributorTitle: card.externalPerson.jobTitle,
        organization: card.externalPerson.organization,
      }
    : undefined;

  return {
    eyebrow: 'Voices',
    title: card.title,
    author: authorPerson || authorExternalPerson,
    ctaLabel: 'Read the essay',
    link: `${Route.VOICES}/${card.slug}`,
    gtm: {
      cardContentType: authorPerson
        ? AnalyticsContent.MELLON_STAFF
        : AnalyticsContent.EXTERNAL_CONTRIBUTOR,
    },
  };
};
