import { type FeatureCard } from '~/v1/_types/FeatureCard';
import { Route } from '~/v1/constants/route';

import { type FeatureCardProps } from './feature';

export const mapFeatureCard = (card: FeatureCard): FeatureCardProps => {
  return {
    image: card.heroImage,
    href: `${Route.FEATURE}/${card.slug}`,
    title: card.name,
    description: card.dateString,
  };
};
