import cx from 'classnames';

import { AnalyticsCard, AnalyticsModule, Event, trackEvent } from '~/ui/components/analytics';
import { FullBleedCTA, FullBleedCTATrigger } from '~/v1/components/fullBleedCta/fullBleedCta';
import { Link } from '~/v1/components/link/link';
import { TEST_ID } from '~/v1/constants/testId';

import { type OpinionCardType } from './opinion.interface';
import styles from './opinion.module.scss';
import { Image } from '../../image/image';

interface OpinionCardProps extends OpinionCardType {
  className?: string;
}

export const OpinionCard: React.FC<OpinionCardProps> = ({
  eyebrow,
  title,
  ctaLabel,
  className,
  author,
  link,
  gtm,
}) => {
  return (
    <FullBleedCTA
      label={title}
      className={cx(styles.card, className)}
      data-test-id={TEST_ID.CARD.OPINION}
    >
      <div className={cx(styles.eyebrow, 'bodySmallTypography')}>{eyebrow}</div>
      <div className={cx(styles.title, 'bodyLargeTypography truncate-6')}>{title}</div>
      <FullBleedCTATrigger>
        <Link
          href={link}
          withIcon
          onClick={() =>
            trackEvent(Event.CARD_CLICK, {
              cardModule: AnalyticsModule.RELATED,
              cardType: AnalyticsCard.VOICES,
              cardContentType: gtm.cardContentType,
              cardCTA: ctaLabel,
              cardTitle: title,
            })
          }
          className={styles.link}
        >
          {ctaLabel}
        </Link>
      </FullBleedCTATrigger>
      {author && (
        <div
          className={cx(styles.author, 'captionTypography', {
            [styles.authorNoImage]: !author.image,
          })}
        >
          {author.image && (
            <Image
              {...{ ...author.image, alt: author.image.title }}
              aspectRatio="4/5"
              fill={false}
              width={96}
              height={120}
              className={styles.image}
            />
          )}
          <div>{author.name}</div>
          <div>{author.contributorTitle}</div>
          <div>{author.organization}</div>
        </div>
      )}
    </FullBleedCTA>
  );
};
