import cx from 'classnames';

import { AnalyticsCard, AnalyticsModule, Event, trackEvent } from '~/ui/components/analytics';
import { Breakpoint } from '~/ui/styles/grid';
import { FullBleedCTA, FullBleedCTATrigger } from '~/v1/components/fullBleedCta/fullBleedCta';
import { type IImage } from '~/v1/components/image/image.interface';
import { Link } from '~/v1/components/link/link';
import { TEST_ID } from '~/v1/constants/testId';

import styles from './feature.module.scss';
import { Image } from '../../image/image';

export interface FeatureCardProps {
  image?: IImage | null;
  title: string;
  ctaText?: string;
  href: string;
  className?: string;
  description: string;
}

export const FeatureCard: React.FC<FeatureCardProps> = ({
  image,
  title,
  ctaText = 'Explore the Special Feature',
  href,
  className,
  description,
}) => {
  return (
    <FullBleedCTA
      label={title}
      className={cx(styles.featureCard, className)}
      data-test-id={TEST_ID.CARD.FEATURE}
    >
      {image && (
        <Image
          aspectRatio="3/2"
          colSpan={{ [Breakpoint.SM]: 4, [Breakpoint.MD]: 3 }}
          className={cx(styles.logoWrapper, styles.image)}
          src={image.src}
          alt={image.title}
        />
      )}

      <div className={styles.cardContent}>
        <div>
          <div className={cx('bodyLargeTypography', styles.title)}>{title}</div>
          <div className={cx('paragraphTypography', styles.description)}>{description}</div>
        </div>

        <FullBleedCTATrigger>
          <Link
            href={href}
            onClick={() =>
              trackEvent(Event.CARD_CLICK, {
                cardModule: AnalyticsModule.RELATED,
                cardType: AnalyticsCard.FEATURE,
                cardCTA: ctaText,
                cardTitle: title,
              })
            }
            withIcon
          >
            {ctaText}
          </Link>
        </FullBleedCTATrigger>
      </div>
    </FullBleedCTA>
  );
};
