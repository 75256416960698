import { parse } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

import { type ArticleCard } from '~/v1/_types/ArticleCard';
import { type ExternalEvent } from '~/v1/_types/ExternalEvent';
import { type NestedArticleCard } from '~/v1/_types/NestedArticleCard';
import { ExternalEventType } from '~/v1/_types/globalTypes';
import { type IImage } from '~/v1/components/image/image.interface';
import { ContentType } from '~/v1/constants/contentType';
import { Route } from '~/v1/constants/route';

import { type EventCardProps } from './event.interface';

export enum EventTypeLabel {
  EVENT = 'Event',
  VIRTUAL_EVENT = 'Virtual event',
}

const DATE_FORMAT = 'yyyy-MM-dd hh:mm a';

export const mapEventCard = (
  data: ArticleCard | NestedArticleCard | ExternalEvent,
): EventCardProps => {
  switch (data.__typename) {
    case ContentType.ARTICLE:
    case ContentType.NESTED_ARTICLE:
      const hasVideo = data.articleBody?.includedEntries.find(
        ({ data }) => data?.__typename === ContentType.SPOTLIGHT_VIDEO,
      );

      return {
        href: `${Route.EVENTS}/${data.slug}`,
        title: data.title,
        coverImage: data.articleHeroImage as IImage,
        type: ExternalEventType.EVENT,
        isPastEvent: true,
        ctaText: hasVideo ? 'Watch event' : 'See event',
      };

    case ContentType.EXTERNAL_EVENT:
      const eventDate = data.eventDate;
      const timeZoneId = determineTimeZoneId(data.timeZone);
      const startDate = zonedTimeToUtc(
        parse(`${eventDate} ${data.startTime}`, DATE_FORMAT, new Date()),
        timeZoneId,
      );
      const endDate = zonedTimeToUtc(
        parse(`${eventDate} ${data.endTime}`, DATE_FORMAT, new Date()),
        timeZoneId,
      );
      const formattedEventDate = new Date(startDate).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        weekday: 'long',
        day: '2-digit',
      });
      const timeFormat = new Intl.DateTimeFormat('en-US', {
        hour: 'numeric',
        minute: '2-digit',
        timeZoneName: 'shortGeneric',
      });
      const timeframe = timeFormat.formatRange(startDate, endDate);

      return {
        href: data.link,
        title: data.name,
        date: formattedEventDate,
        time: timeframe,
        image: data.logo as IImage,
        type: data.eventType,
        isPastEvent: false,
        ctaText: 'See details',
      };

    default:
      throw Error(`Invalid type ${data.__typename}`);
  }
};

function determineTimeZoneId(timeZone: string): string {
  switch (timeZone) {
    case 'Eastern':
      return 'America/New_York';
    case 'Central':
      return 'America/Chicago';
    case 'Mountain':
      return 'America/Denver';
    case 'Pacific':
      return 'America/Los_Angeles';
    case 'Alaska':
      return 'America/Anchorage';
    case 'Hawaii':
      return 'Pacific/Honolulu';
    default:
      return '';
  }
}
