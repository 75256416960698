import { type Idea } from '~/v1/_types/Idea';
import { Route } from '~/v1/constants/route';

import { type IdeaCardProps } from './idea';

export const mapIdeaCard = (card: Idea): IdeaCardProps => {
  return {
    image: card.heroImage,
    href: `${Route.IDEA}/${card.slug}`,
    idea: card.name,
    question: card.question,
  };
};
