import { useState } from 'react';

import { type Media } from '~/v1/_types/Media';

import { type AspectRatio, type IImage, ImageOrientation } from './image.interface';

function isMedia(image: IImage | Media): image is Media {
  return 'mediaHeight' in image;
}

export function useAspectRatio(image?: IImage | Media | null) {
  const [aspectRatio, setAspectRatio] = useState<AspectRatio | null>(() => {
    if (!image) {
      return null;
    }
    if (!isMedia(image) || !image.mediaHeight || !image.mediaWidth) {
      return {
        orientation: ImageOrientation.LANDSCAPE,
        ratio: 0,
      };
    }
    return {
      orientation:
        image.mediaWidth > image.mediaHeight
          ? ImageOrientation.LANDSCAPE
          : ImageOrientation.PORTRAIT,
      ratio: image.mediaWidth / image.mediaHeight,
    };
  });

  return [aspectRatio, setAspectRatio] as const;
}
