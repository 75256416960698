import cx from 'classnames';
import { ReactSVG } from 'react-svg';

import {
  AnalyticsCard,
  AnalyticsContent,
  type AnalyticsModule,
  Event,
  trackEvent,
} from '~/ui/components/analytics';
import { Breakpoint } from '~/ui/styles/grid';
import { FullBleedCTA, FullBleedCTATrigger } from '~/v1/components/fullBleedCta/fullBleedCta';
import { Link } from '~/v1/components/link/link';
import { LABELS } from '~/v1/components/link/linkLabels';
import { TEST_ID } from '~/v1/constants/testId';

import { type NewsCardType } from './news.interface';
import styles from './news.module.scss';
import { Image } from '../../image/image';

interface NewsCardProps extends NewsCardType {
  gtm: {
    module: AnalyticsModule;
  };
  className?: string;
}

export const NewsCard: React.FC<NewsCardProps> = ({
  logo,
  eyebrow,
  description,
  ctaText,
  ctaUrl,
  isExternalNews,
  className,
  gtm,
}) => {
  return (
    <FullBleedCTA
      label={description || ''}
      className={cx(styles.newsCard, className)}
      data-test-id={TEST_ID.CARD.NEWS}
    >
      <div>
        {logo && (
          <div className={styles.logoWrapper}>
            {logo.src.endsWith('.svg') ? (
              <ReactSVG src={logo.src} className={styles.logo} title={logo.title} />
            ) : (
              <Image
                className={styles.logo}
                colSpan={{ [Breakpoint.SM]: 1 }}
                src={logo.src}
                alt={logo.title}
                aspectRatio="4/1"
              />
            )}
          </div>
        )}
        {eyebrow && <div className={cx('bodySmallTypography', styles.eyebrow)}>{eyebrow}</div>}

        {description && (
          <div className={cx('bodyLargeTypography truncate-3', styles.description)}>
            {description}
          </div>
        )}
      </div>

      <FullBleedCTATrigger>
        <Link
          href={ctaUrl}
          onClick={() =>
            trackEvent(Event.CARD_CLICK, {
              cardModule: gtm.module,
              cardType: AnalyticsCard.NEWS,
              cardContentType: isExternalNews
                ? AnalyticsContent.EXTERNAL_NEWS
                : AnalyticsContent.INTERNAL_NEWS,
              cardCTA: ctaText,
              cardTitle: description || '',
            })
          }
          className={styles.link}
          withIcon
        >
          {ctaText || LABELS.READ_NEWS}
        </Link>
      </FullBleedCTATrigger>
    </FullBleedCTA>
  );
};
